import { Tooltip } from '@abyss/web/ui/Tooltip';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useCurrentUser } from '@src/features/Users/hooks/useCurrentUser';
import { getRolesWithAttribute } from '@src/features/Users/includes/permissions';
import { isEmpty, isNil, isNull } from 'lodash';
import PropTypes from 'prop-types';
import React, { Children, cloneElement, useMemo } from 'react';

import { Styles } from './includes/styles';

/**
 * Capability
 *
 * When the button is disabled, wrap it in a Tooltip explaining why it is disabled.
 *
 * @param props
 * @returns {React.JSX.Element|*}
 * @constructor
 */
export const Capability = (props) => {
  const { alias, attributes, children, fallback, isAllowed, method, resource } = props;

  const { hasPermission } = useCurrentUser();

  const capability = useMemo(() => {
    const theCapability = {
      attribute: '',
      isAllowed,
      message: '',
    };

    const allowedRoles = [];
    const allowedAttributes = {};

    if (isNull(isAllowed)) {
      attributes.forEach((attribute) => {
        theCapability.attribute = attribute;

        allowedAttributes[attribute] = hasPermission(resource, attribute);

        const rolesWithAttribute = getRolesWithAttribute(resource, attribute, true);

        rolesWithAttribute.forEach((role) => {
          if (!allowedRoles.includes(role)) {
            allowedRoles.push(role);
          }
        });
      });

      if (!isEmpty(alias)) {
        theCapability.attribute = alias;
      }

      theCapability.isAllowed = Object.values(allowedAttributes).every((value) => {
        return value === true;
      });
    }

    if (!theCapability.isAllowed) {
      if (isEmpty(allowedRoles)) {
        theCapability.message = `There are no roles with the required permissions to access this feature.`;
      } else {
        theCapability.message = `Required Permissions: ${allowedRoles.join(' Access, ')} Access`;
      }
    }

    return theCapability;
  }, [resource, attributes, isAllowed]);

  return (
    <ErrorHandler location="src/features/Users/components/Capability/Capability.jsx">
      {method === 'hide' && !isNil(fallback) && fallback}

      {method === 'disable' &&
        (capability.isAllowed === true ? (
          children
        ) : (
          <Styles>
            <Tooltip asChild={false} content={capability.message} css={{ width: '100%' }}>
              {Children.map(children, (child) => {
                if (!React.isValidElement(child)) {
                  return child;
                }
                return cloneElement(child, {
                  ...child.props,
                  className: 'isDisabled-true',
                  disabled: true,
                  isDisabled: true,
                });
              })}
            </Tooltip>
          </Styles>
        ))}
    </ErrorHandler>
  );
};

Capability.propTypes = {
  alias: PropTypes.string,
  attributes: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node,
  isAllowed: PropTypes.bool,
  method: PropTypes.oneOf(['disable', 'hide']),
  resource: PropTypes.string.isRequired,
};

Capability.defaultProps = {
  alias: '',
  fallback: null,
  isAllowed: null,
  method: 'disable',
};
