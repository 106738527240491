import { useFormFieldArray } from '@abyss/web/hooks/useFormFieldArray';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { User } from '@src/features/Users/components/User';
import PropTypes from 'prop-types';
import React from 'react';

import { Table } from './components/Table';
import { Styles } from './includes/styles';

/**
 * RepeatableFields
 *
 * @TODO Needs description.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const RepeatableFields = (props) => {
  const { accessor, defaultValues, disabledFields, fields, form, permissions } = props;

  const {
    append,
    fields: repeatableFields,
    remove,
  } = useFormFieldArray({
    control: form.control,
    name: accessor,
  });

  const values = form?.getValues(accessor);

  return (
    <ErrorHandler location="src/components/RepeatableFields/RepeatableFields.jsx">
      <Styles>
        <Grid>
          <Grid.Col span={{ xs: '100%' }}>
            <Layout.Group alignLayout="right">
              <User.Capability
                alias="add"
                attributes={permissions?.add?.attributes}
                isAllowed={permissions?.add?.isAllowed}
                resource={permissions?.add?.resource}
              >
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  id="addRow"
                  onClick={() => {
                    append(defaultValues);
                  }}
                  variant="custom"
                >
                  <Flex alignItems="center">
                    <div>
                      <IconSymbol icon="add_circle" variant="filled" />
                      <IconSymbol icon="add_circle" variant="outlined" />
                    </div>
                    <div>Add Row</div>
                  </Flex>
                </Link>
              </User.Capability>
            </Layout.Group>
            <Table
              {...{
                accessor,
                disabledFields,
                fields,
                permissions,
                remove,
                repeatableFields,
                rows: values,
              }}
            />
          </Grid.Col>
        </Grid>
      </Styles>
    </ErrorHandler>
  );
};

RepeatableFields.propTypes = {
  accessor: PropTypes.string,
  defaultValues: PropTypes.arrayOf(PropTypes.shape({})),
  disabledFields: PropTypes.shape({}),
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  form: PropTypes.shape({
    control: PropTypes.shape({
      fields: PropTypes.shape({
        append: PropTypes.func,
        remove: PropTypes.func,
      }),
    }),
    getValues: PropTypes.func,
  }),
  permissions: PropTypes.shape({
    add: {
      attributes: [PropTypes.arrayOf(PropTypes.string)],
      isAllowed: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
      resource: PropTypes.string,
    },
    edit: {
      attributes: [PropTypes.arrayOf(PropTypes.string)],
      isAllowed: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
      resource: PropTypes.string,
    },
    remove: {
      attributes: [PropTypes.arrayOf(PropTypes.string)],
      isAllowed: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
      resource: PropTypes.string,
    },
  }),
};

RepeatableFields.defaultProps = {
  accessor: '',
  defaultValues: [],
  disabledFields: {},
  fields: [],
  form: {
    control: {
      fields: {
        append: () => {},
        remove: () => {},
      },
    },
    getValues: () => {},
  },
  permissions: {
    add: {
      attributes: [],
      isAllowed: null,
      resource: '',
    },
    edit: {
      attributes: [],
      isAllowed: null,
      resource: '',
    },
    remove: {
      attributes: [],
      isAllowed: null,
      resource: '',
    },
  },
};
