import { Popover } from '@abyss/web/ui/Popover';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { User } from '@src/features/Users/components/User';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { NameField } from '../../fields/Name';

/**
 * Button: EditName
 *
 * This button is used to edit the name of the entity.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const EditName = (props) => {
  const { currentEntity, form, refetch } = props;

  const [isOpen, setIsOpen] = useState(null);

  return (
    <ErrorHandler location="src/routes/private/Admin/screens/CommonCriteria/components/buttons/EditName/EditName.jsx">
      <Popover
        align="start"
        alignOffset={24}
        content={<NameField currentEntity={currentEntity} form={form} refetch={refetch} />}
        css={{ '.abyss-popover-content-container': { overflow: 'visible !important' } }}
        onOpenChange={() => {
          return isOpen === currentEntity?.id ? setIsOpen(null) : setIsOpen(currentEntity?.id);
        }}
        open={isOpen === currentEntity?.id}
        position="top"
        title=""
        width="auto"
      >
        <User.Capability attributes={['edit']} resource="Admin:CommonCriteria">
          <Button
            onClick={() => {
              return setIsOpen(currentEntity?.id);
            }}
            variant="outline"
          >
            Edit Name
          </Button>
        </User.Capability>
      </Popover>
    </ErrorHandler>
  );
};

EditName.propTypes = {
  currentEntity: PropTypes.shape({
    id: PropTypes.string,
  }),
  form: PropTypes.shape({
    clearErrors: PropTypes.func,
    getValues: PropTypes.func,
    register: PropTypes.func,
    setError: PropTypes.func,
    trigger: PropTypes.func,
    validate: PropTypes.func,
  }),
  refetch: PropTypes.func,
};

EditName.defaultProps = {
  currentEntity: {},
  form: {},
  refetch: () => {},
};
