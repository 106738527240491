import { DropdownMenu } from '@abyss/web/ui/DropdownMenu';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Table as TableComponent } from '@src/components/Table-query';
import { useCurrentUser } from '@src/features/Users/hooks/useCurrentUser';
import { orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import configuration from './includes/configuration.json';
import { Styles } from './includes/styles';

/**
 * Table
 *
 * Displays a list of code categories within a DataTable.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const {
    error,
    isLoading,
    requestArgs,
    requestFunction,
    requestKey,
    rows,
    setCurrentEntity,
    setIsFormModalOpen,
    totalPages,
    totalRecords,
  } = props;

  const { hasPermission } = useCurrentUser();

  const canEdit = hasPermission('Admin:CodeCategories', 'edit');

  /**
   * renderCellActions
   *
   * Provides the user with a menu of actionable items to perform on a specific action path within the list of action
   * paths.
   *
   * @param row
   * @returns {Element}
   */
  const renderCellActions = ({ row }) => {
    const menuItems = [
      {
        disabled: !canEdit,
        icon: <IconSymbol icon="edit" />,
        onClick: () => {
          setIsFormModalOpen(true);
          setCurrentEntity(row?.original);
        },
        title: 'Edit',
      },
    ];

    return (
      <div
        style={{
          marginLeft: '-10px',
          marginRight: '-10px',
        }}
      >
        <Layout.Group
          css={{
            height: '100%',
            marginLeft: '2px',
            marginRight: '2px',
          }}
        >
          <DropdownMenu
            before={<IconSymbol color="#6F6F6F" icon="more_vert" size={24} />}
            hideLabel
            menuItems={menuItems}
          />
        </Layout.Group>
      </div>
    );
  };

  /**
   * renderCellIsValid
   *
   * display the value of the isValid field as a string
   *
   * @param value
   * @returns {string}
   */
  const renderCellIsValid = ({ value }) => {
    return String(value).toUpperCase();
  };

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return orderBy(configuration?.initialColumns, ['order'], ['asc']).map((item) => {
      const column = item;

      if (column.accessor === 'actions') {
        column.Cell = renderCellActions;
      }

      if (column.accessor === 'isValid') {
        column.Cell = renderCellIsValid;
      }

      return column;
    });
  }, [canEdit]);

  return (
    <ErrorHandler location="src/routes/private/Admin/screens/CodeCategories/List/components/Table/Table.jsx">
      <Styles>
        <TableComponent
          {...{
            columns,
            configuration,
            error,
            isLoading,
            noDataMessage: 'No code categories found.',
            requestArgs,
            requestFunction,
            requestKey,
            rows,
            totalPages,
            totalRecords,
          }}
        />
      </Styles>
    </ErrorHandler>
  );
};

Table.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
    variant: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  requestArgs: PropTypes.shape({
    page: PropTypes.number,
    size: PropTypes.number,
    sort: PropTypes.string,
  }),
  requestFunction: PropTypes.func,
  requestKey: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      categoryCode: PropTypes.string,
      categoryDesc: PropTypes.string,
      isValid: PropTypes.bool,
    })
  ),
  setCurrentEntity: PropTypes.func,
  setIsFormModalOpen: PropTypes.func,
  totalPages: PropTypes.number,
  totalRecords: PropTypes.number,
};

Table.defaultProps = {
  error: null,
  isLoading: false,
  requestArgs: {},
  requestFunction: () => {},
  requestKey: '',
  rows: [],
  setCurrentEntity: () => {},
  setIsFormModalOpen: () => {},
  totalPages: 0,
  totalRecords: 0,
};
