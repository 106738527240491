import { useInterval } from '@abyss/web/hooks/useInterval';
import { Badge } from '@abyss/web/ui/Badge';
import { Divider } from '@abyss/web/ui/Divider';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { RiskCodesTooltip, SourcesTooltip } from '@src/components/common/tooltips';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Table as TableComponent } from '@src/components/Table-query';
import { useApi } from '@src/context/Api';
import { AppliedFilterCriteria } from '@src/features/Criteria/components/misc/AppliedFilterCriteria';
import { isArray, isEmpty, isUndefined, merge } from 'lodash';
import { uniq } from 'lodash/array';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { ExpansionRow } from './components/ExpansionRow';
import { Header } from './components/Header';
import { ExportStatus } from './components/Header/components/ExportStatus';
import { TotalRecords } from './components/Header/components/TotalRecords';
import configuration from './includes/configuration.json';

/**
 * Table: RiskRecords
 *
 * @TODO - Needs description.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const RiskRecords = (props) => {
  const {
    allowExport,
    context,
    criteria,
    currentEntity,
    showAnalyzeRisks,
    showFilters,
    showHeaderLeft,
    showHeaderTop,
    showTotalRecords,
  } = props;

  const { clearApiCache, useApiQuery } = useApi();

  const [ListRiskRecords, { data, error, isFetching, isLoading }] = useApiQuery('ListRiskRecords');
  const [ListRiskCodes, { data: riskCodes, isFetching: riskCodesFetching, isLoading: riskCodesLoading }] =
    useApiQuery('ListRiskCodes');
  const [GetRiskRecordsCount, { data: riskRecordsCount }] = useApiQuery('GetRiskRecordsCount');
  const [GetExportDetails, { data: exportData, refetch: exportRefetch }] = useApiQuery('GetExportDetails');

  const [pollingRate, setPollingRate] = useState(null);

  /**
   * Get the list of risk codes if not already fetched.
   */
  useEffect(() => {
    if (isUndefined(riskCodes)) {
      ListRiskCodes();
    }
  }, [riskCodes]);

  /**
   * Get the total number of risk records if not already fetched.
   */
  useEffect(() => {
    if (showHeaderTop === true && showTotalRecords === true) {
      if (isUndefined(riskRecordsCount) && !isEmpty(criteria?.[context]?.merged)) {
        if (context === 'entrance') {
          GetRiskRecordsCount({
            criteria: {
              entrance: criteria?.entrance,
            },
          });
        }

        if (context === 'exit') {
          GetRiskRecordsCount({ criteria });
        }
      }
    }
  }, [riskRecordsCount, criteria, showTotalRecords, showHeaderTop]);

  /**
   * Get export details if not already fetched.
   */
  useEffect(() => {
    if (allowExport === true && showHeaderTop === true && showHeaderLeft === true) {
      if (isUndefined(exportData) && currentEntity?.type === 'actionPath' && currentEntity?.id) {
        GetExportDetails({ actionPathId: currentEntity?.id });
      }
    }
  }, [exportData, currentEntity, showHeaderTop, showHeaderLeft, allowExport]);

  /**
   * Start polling for status changes.
   */
  useEffect(() => {
    if (allowExport === true && showHeaderTop === true && showHeaderLeft === true) {
      if (['RUNNING', 'SCHEDULED'].includes(exportData?.status)) {
        setPollingRate(15000);
      } else {
        setPollingRate(null);
      }
    }
  }, [exportData, showHeaderTop, showHeaderLeft, allowExport]);

  useInterval(async () => {
    if (allowExport === true && showHeaderTop === true && showHeaderLeft === true) {
      clearApiCache(['GetExportDetails']);
      GetExportDetails({ actionPathId: currentEntity?.id });
    }
  }, pollingRate);

  /**
   * renderCellEID
   *
   * renders a link to open in a remote system (HCM - Health Care Matrix)
   *
   * @param cell
   * @returns {JSX.Element}
   */
  const renderCellEID = ({ cell }) => {
    return <Link href={`/analysis/eid-search/${cell.value}/`}>{cell.value}</Link>;
  };

  /**
   * renderCellRiskScore
   *
   * @TODO - Needs description.
   *
   * @param cell
   * @returns {JSX.Element|*}
   */
  const renderCellRiskScore = ({ cell }) => {
    const codeNumbers = cell?.value?.split('-');

    const letter = codeNumbers.shift().toUpperCase();

    const codeDescriptions = [];

    codeNumbers.forEach((codeNumber) => {
      const match = riskCodes?.find((riskCode) => {
        return riskCode?.codeId === String(codeNumber);
      });

      if (!isUndefined(match) && !codeDescriptions.includes(match)) {
        codeDescriptions.push(match);
      }
    });

    if (letter === 'R') {
      return (
        <Layout.Group space={themeConfiguration?.theme?.space?.md}>
          <Badge outline variant="error">
            <Tooltip content={<RiskCodesTooltip codeDetails={codeDescriptions} />} placement="auto">
              <div>{cell?.value}</div>
            </Tooltip>
          </Badge>
        </Layout.Group>
      );
    }

    if (letter === 'Y') {
      return (
        <Layout.Group space={themeConfiguration?.theme?.space?.md}>
          <Badge outline variant="warning">
            <Tooltip content={<RiskCodesTooltip codeDetails={codeDescriptions} />} placement="auto">
              <div>{cell?.value}</div>
            </Tooltip>
          </Badge>
        </Layout.Group>
      );
    }

    if (letter === 'G') {
      return (
        <Layout.Group space={themeConfiguration?.theme?.space?.md}>
          <Badge outline variant="success">
            {cell?.value}
          </Badge>
        </Layout.Group>
      );
    }

    return cell.value;
  };

  /**
   * renderCellTrustedSources
   *
   * @TODO - Needs description.
   *
   * @param row
   * @returns {JSX.Element|string}
   */
  const renderCellTrustedSources = ({ row }) => {
    const sources = [];

    row?.original?.ireRiskRecord?.trustedRecordSources?.forEach((trustedSource) => {
      if (!sources.includes(trustedSource?.source)) {
        sources.push(trustedSource?.source);
      }
    });

    return (
      <Tooltip content={<SourcesTooltip sources={sources} />} placement="auto">
        <span className="truncate">{sources.join(', ')}</span>
      </Tooltip>
    );
  };

  /**
   * renderCellUntrustedSources
   *
   * @TODO - Needs description.
   *
   * @param row
   * @returns {JSX.Element|string}
   */
  const renderCellUntrustedSources = ({ row }) => {
    if (isArray(row?.original?.ireRiskRecord?.remediationFindings)) {
      let sources = [];

      row?.original?.ireRiskRecord?.remediationFindings.forEach((attribute) => {
        if (isArray(attribute.untrustedRecordSources)) {
          attribute.untrustedRecordSources.forEach((untrustedSource) => {
            if (!sources.includes(untrustedSource.source)) {
              sources.push(untrustedSource.source);
            }
          });
        }
      });

      sources = uniq(sources);

      return (
        <Tooltip content={<SourcesTooltip sources={sources} />} placement="auto">
          <span className="truncate">{sources.join(', ')}</span>
        </Tooltip>
      );
    }
    return '';
  };

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return configuration?.initialColumns.map((item) => {
      const column = item;

      if (column.Header === 'EID') {
        column.Cell = renderCellEID;
      }

      if (column.Header === 'Risk Score') {
        column.Cell = renderCellRiskScore;
      }

      if (column.Header === 'Trusted Sources') {
        column.Cell = renderCellTrustedSources;
      }

      if (column.Header === 'Untrusted Sources') {
        column.Cell = renderCellUntrustedSources;
      }

      return column;
    });
  }, [riskCodes]);

  return (
    <ErrorHandler location="src/common/tables/RiskRecords/Table.jsx">
      <TableComponent
        {...{
          columns,
          configuration: merge({}, configuration, {
            renderSubComponent: ({ original }) => {
              return <ExpansionRow row={original} />;
            },
          }),
          error,
          headerLeft: (
            <React.Fragment>
              {showHeaderLeft === true && (
                <Header
                  allowExport={allowExport}
                  criteria={criteria}
                  currentEntity={currentEntity}
                  exportDetails={exportData}
                  exportRefetch={exportRefetch}
                  pollingRate={pollingRate}
                  riskRecordCount={riskRecordsCount?.entranceCriteriaCount}
                  setPollingRate={setPollingRate}
                  showAnalyzeRisks={showAnalyzeRisks}
                />
              )}
            </React.Fragment>
          ),
          headerTop: (
            <React.Fragment>
              {showHeaderTop === true && (
                <Layout.Group space={themeConfiguration?.theme?.space?.sm}>
                  {showTotalRecords === true && (
                    <TotalRecords riskRecordCount={riskRecordsCount?.entranceCriteriaCount} />
                  )}

                  {showTotalRecords === true && allowExport === true && (
                    <Divider height={24} orientation="vertical" width={1} />
                  )}

                  {allowExport === true && <ExportStatus exportDetails={exportData} />}

                  {showFilters === true && [
                    <React.Fragment key="additional-criteria">
                      {!isEmpty(criteria?.entrance?.additional) && [
                        <Divider height={24} key="divider-additional" orientation="vertical" width={1} />,
                        <AppliedFilterCriteria
                          filters={criteria?.entrance?.additional}
                          key="applied-additional"
                          label="Additional Criteria"
                        />,
                      ]}
                    </React.Fragment>,
                    <React.Fragment key="common-criteria">
                      {!isEmpty(criteria?.entrance?.commonIds) && [
                        <Divider height={24} key="divider-common" orientation="vertical" width={1} />,
                        <AppliedFilterCriteria criteria={criteria} key="applied-common" label="Common Criteria" />,
                      ]}
                    </React.Fragment>,
                  ]}
                </Layout.Group>
              )}
            </React.Fragment>
          ),
          isLoading: isLoading || isFetching || riskCodesLoading || riskCodesFetching,
          requestArgs: {
            criteria:
              context === 'entrance'
                ? {
                    entrance: criteria?.entrance,
                  }
                : criteria,
            page: 0,
            size: 25,
            sort: 'eid,asc',
          },
          requestFunction: ListRiskRecords,
          requestKey: ListRiskRecords,
          rows: data?.content,
          totalPages: data?.totalPages,
          totalRecords: data?.totalElements,
        }}
      />
    </ErrorHandler>
  );
};

RiskRecords.propTypes = {
  allowExport: PropTypes.bool,
  context: PropTypes.string,
  criteria: PropTypes.shape({
    entrance: PropTypes.shape({
      additional: PropTypes.arrayOf(
        PropTypes.shape({
          column: PropTypes.string,
          condition: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
        })
      ),
      common: PropTypes.arrayOf(
        PropTypes.shape({
          column: PropTypes.string,
          condition: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
        })
      ),
      commonCriteriaVersionIds: PropTypes.arrayOf(PropTypes.string),
      commonIds: PropTypes.arrayOf(PropTypes.string),
      merged: PropTypes.arrayOf(
        PropTypes.shape({
          column: PropTypes.string,
          condition: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
        })
      ),
    }),
  }),
  currentEntity: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
  }),
  showAnalyzeRisks: PropTypes.bool,
  showFilters: PropTypes.bool,
  showHeaderLeft: PropTypes.bool,
  showHeaderTop: PropTypes.bool,
  showTotalRecords: PropTypes.bool,
};

RiskRecords.defaultProps = {
  allowExport: false,
  context: 'entrance',
  criteria: {
    entrance: {
      additional: [],
      common: [],
      commonCriteriaVersionIds: [],
      commonIds: [],
      merged: [],
    },
  },
  currentEntity: {},
  showAnalyzeRisks: false,
  showFilters: false,
  showHeaderLeft: true,
  showHeaderTop: true,
  showTotalRecords: false,
};
