import { useForm } from '@abyss/web/hooks/useForm';
import { dayjs } from '@abyss/web/tools/dayjs';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid';
import { Layout } from '@abyss/web/ui/Layout';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Loader } from '@src/components/Loader';
import { useApi } from '@src/context/Api';
import { Filters } from '@src/features/Criteria/components/misc/Filters';
import { useCurrentUser } from '@src/features/Users/hooks/useCurrentUser';
import { motion } from 'framer-motion';
import { isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSave } from '../../../hooks/useSave';
import { ActivateButton } from '../../buttons/Activate';
import { DeactivateButton } from '../../buttons/Deactivate';
import { EditNameButton } from '../../buttons/EditName';
import { VersionField } from '../../fields/Version';

/**
 * Form: Edit
 *
 * This form is used to edit the common criteria filters.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Edit = (props) => {
  const { currentEntity, refetch, setFocusedEntity, setShowAll } = props;

  const { hasPermission } = useCurrentUser();

  const canEdit = hasPermission('Admin:CommonCriteria', 'edit');

  const [isSaving, setIsSaving] = useState(false);

  const { useApiQuery } = useApi();
  const [
    ListCommonCriteriaVersions,
    {
      data: CommonCriteriaVersions,
      isFetching: isCommonCriteriaVersionsFetching,
      isLoading: isCommonCriteriaVersionsLoading,
    },
  ] = useApiQuery('ListCommonCriteriaVersions');

  const handleSave = useSave('update');
  const handleCreateVersion = useSave('createVersion');

  const defaultValues = {
    filters: [],
    id: '',
    name: '',
    version: 1,
  };

  const form = useForm({ defaultValues });

  const { isDirty, isSubmitting, isValid } = form.formState;

  /**
   * Mapping currentEntity loaded from API to the form state.
   */
  useEffect(() => {
    const data = defaultValues;

    data.id = currentEntity?.id;
    data.name = currentEntity?.name;

    if (currentEntity?.isActive === true) {
      data.version = currentEntity?.activeVersionNbr;

      if (!isUndefined(currentEntity?.activeCommonCriteriaVersion?.criteria)) {
        data.filters = currentEntity?.activeCommonCriteriaVersion?.criteria.map((filter) => {
          const theFilter = { ...filter };

          if (['CREATED_DATE', 'LAST_MODIFIED_DATE'].includes(theFilter?.column)) {
            theFilter.value = dayjs(theFilter?.value).format('MM/DD/YYYY');
          }

          if (theFilter?.column?.toLowerCase().includes('count')) {
            theFilter.value = String(theFilter?.value);
          }

          return theFilter;
        });
      }
    }

    if (currentEntity?.isActive === false) {
      data.version = 1;
    }

    form?.reset(data, {
      keepDirty: false,
      keepDirtyValues: false,
      keepErrors: false,
      keepIsValid: false,
      keepSubmitCount: true,
      keepTouched: false,
      keepValues: false,
    });
  }, [currentEntity]);

  useEffect(() => {
    if (isUndefined(CommonCriteriaVersions)) {
      ListCommonCriteriaVersions({
        id: currentEntity?.id,
        page: 0,
        size: 9999,
        sort: 'activeVersionNbr,asc',
      });
    }
  }, [CommonCriteriaVersions, currentEntity]);

  const selectedVersionValue = form?.watch('version');

  const activatedVersionValue = useMemo(() => {
    return currentEntity?.activeVersionNbr;
  }, [currentEntity]);

  const activatedVersion = useMemo(() => {
    let theVersion = {};

    if (!isUndefined(CommonCriteriaVersions?.content) && !isEmpty(CommonCriteriaVersions?.content)) {
      const matchingVersion = CommonCriteriaVersions?.content?.find((CommonCriteriaVersion) => {
        return CommonCriteriaVersion?.version === activatedVersionValue;
      });

      if (!isUndefined(matchingVersion)) {
        theVersion = matchingVersion;
      }
    }

    return theVersion;
  }, [CommonCriteriaVersions, activatedVersionValue]);

  const selectedVersion = useMemo(() => {
    let theVersion = {};

    if (!isUndefined(CommonCriteriaVersions?.content) && !isEmpty(CommonCriteriaVersions?.content)) {
      const matchingVersion = CommonCriteriaVersions?.content?.find((CommonCriteriaVersion) => {
        return CommonCriteriaVersion?.version === selectedVersionValue;
      });

      if (!isUndefined(matchingVersion)) {
        theVersion = matchingVersion;
      }
    }

    return theVersion;
  }, [CommonCriteriaVersions, selectedVersionValue]);

  useEffect(() => {
    if (!isEmpty(selectedVersion)) {
      const currentFilters = form?.getValues('filters');
      if (selectedVersion?.criteria !== currentFilters) {
        form?.setValue('filters', selectedVersion?.criteria);
      }
    }
  }, [selectedVersion]);

  /**
   * handleSubmit
   *
   * Calls a remote API to save the common criteria.
   *
   * @returns {Promise<void>}
   */
  const handleSubmit = useCallback(
    async (submittedValues) => {
      if (!isSaving && isValid && !isSubmitting && isDirty) {
        setIsSaving(true);
        if (submittedValues?.filters === currentEntity?.activeCommonCriteriaVersion?.criteria) {
          await handleSave(submittedValues);
        } else {
          await handleCreateVersion(submittedValues);
        }
        setIsSaving(false);
      }
    },
    [isSaving, isDirty, isValid, isSubmitting, form?.formState?.errors]
  );

  if (isCommonCriteriaVersionsLoading || isCommonCriteriaVersionsFetching) {
    return <Loader height="100%" verticalAlignment="top" width="100%" />;
  }

  return (
    <ErrorHandler location="src/routes/private/Admin/screens/CommonCriteria/components/forms/Edit/Edit.jsx">
      <motion.div
        animate="open"
        initial={{ opacity: 0 }}
        variants={{
          closed: { opacity: 0 },
          open: { opacity: 1 },
        }}
      >
        <FormProvider autoComplete="off" highlighted onSubmit={handleSubmit} state={form}>
          <Grid>
            <Grid.Col
              span={{
                xs: '100%',
              }}
            >
              <Filters
                form={form}
                headerLeft={
                  <Layout.Group alignItems="bottom" space={themeConfiguration?.theme?.space?.lg}>
                    <VersionField
                      criteriaVersions={CommonCriteriaVersions?.content}
                      currentEntity={currentEntity}
                      form={form}
                    />
                    <EditNameButton currentEntity={currentEntity} form={form} refetch={refetch}>
                      Edit Name
                    </EditNameButton>
                  </Layout.Group>
                }
                isDisabled={currentEntity?.isActive === false}
                isLoading={
                  isSaving || isSubmitting || isCommonCriteriaVersionsLoading || isCommonCriteriaVersionsFetching
                }
                permissions={{
                  add: {
                    attributes: ['edit'],
                    isAllowed: canEdit,
                    resource: 'Admin:CommonCriteria',
                  },
                  edit: {
                    attributes: ['edit'],
                    isAllowed: canEdit,
                    resource: 'Admin:CommonCriteria',
                  },
                  remove: {
                    attributes: ['edit'],
                    isAllowed: canEdit,
                    resource: 'Admin:CommonCriteria',
                  },
                }}
              />
            </Grid.Col>
            <Grid.Col
              span={{
                xs: '100%',
              }}
            >
              <Layout.Group alignLayout="right">
                {currentEntity?.isActive === true && (
                  <DeactivateButton
                    activatedVersion={activatedVersion}
                    currentEntity={currentEntity}
                    form={form}
                    refetch={refetch}
                    setFocusedEntity={setFocusedEntity}
                    setShowAll={setShowAll}
                  >
                    Deactivate
                  </DeactivateButton>
                )}

                <ActivateButton
                  activatedVersion={activatedVersion}
                  commonCriteriaVersions={CommonCriteriaVersions?.content.length + 1}
                  currentEntity={currentEntity}
                  form={form}
                  refetch={refetch}
                  setFocusedEntity={setFocusedEntity}
                >
                  Activate
                </ActivateButton>
              </Layout.Group>
            </Grid.Col>
          </Grid>
        </FormProvider>
      </motion.div>
    </ErrorHandler>
  );
};

Edit.propTypes = {
  currentEntity: PropTypes.shape({
    activeCommonCriteriaVersion: PropTypes.shape({
      criteria: PropTypes.arrayOf(
        PropTypes.shape({
          column: PropTypes.string,
          condition: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
      ),
    }),
    activeVersionNbr: PropTypes.number,
    id: PropTypes.string,
    isActive: PropTypes.bool,
    name: PropTypes.string,
  }),
  refetch: PropTypes.func,
  setFocusedEntity: PropTypes.func,
  setShowAll: PropTypes.func,
};

Edit.defaultProps = {
  currentEntity: {},
  refetch: () => {},
  setFocusedEntity: () => {},
  setShowAll: () => {},
};
