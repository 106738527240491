import { PageSize } from '@abyss/web/ui/Pagination';
import { ErrorHandler } from '@src/components/ErrorHandler';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * ResultsPerPage
 *
 * Allows the user to select the number of results to display per page.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const ResultsPerPage = (props) => {
  const { pageSizeOptions, resultsPerPage, setResultsPerPage } = props;

  return (
    <ErrorHandler location="src/components/Table-query/components/Header/components/ResultsPerPage/ResultsPerPage.jsx">
      <PageSize pageSize={resultsPerPage} pageSizeOptions={pageSizeOptions} setPageSize={setResultsPerPage} />
    </ErrorHandler>
  );
};

ResultsPerPage.propTypes = {
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  resultsPerPage: PropTypes.number,
  setResultsPerPage: PropTypes.func,
};

ResultsPerPage.defaultProps = {
  pageSizeOptions: [],
  resultsPerPage: 0,
  setResultsPerPage: () => {},
};
