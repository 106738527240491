import { DropdownMenu } from '@abyss/web/ui/DropdownMenu';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Table as TableComponent } from '@src/components/Table-query';
import { User } from '@src/features/Users/components/User';
import { useCurrentUser } from '@src/features/Users/hooks/useCurrentUser';
import { merge, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import configuration from './includes/configuration.json';
import { Styles } from './includes/styles';

/**
 * Table: AssociatedEids
 *
 * Displays a list of EID's associated with an action path within a DataTable.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const {
    error,
    isLoading,
    requestArgs,
    requestFunction,
    requestKey,
    rows,
    setEids,
    setIsOpen,
    totalPages,
    totalRecords,
  } = props;

  const { hasPermission } = useCurrentUser();

  const canDetach = hasPermission('ActionPaths', 'detach');

  /**
   * renderCellActions
   *
   * Displays a dropdown menu with actions for the row.
   *
   * @param row
   * @returns {Element}
   */
  const renderCellActions = ({ row }) => {
    return (
      <User.Capability attributes={['detach']} resource="ActionPaths">
        <Button
          className="eid-remove-button"
          onClick={() => {
            setEids([row?.original?.eid]);
            setIsOpen(true);
          }}
          rounded
          size="$sm"
          variant="ghost"
        >
          <IconSymbol className="eid-remove-icon" icon="remove_circle_outline" variant="outlined" />
        </Button>
      </User.Capability>
    );
  };

  /**
   * renderCellEid
   *
   * Renders the EID in the cell with a link to the eid search results.
   *
   * @param cell
   * @returns {*}
   */
  const renderCellEid = ({ value }) => {
    return <Link href={`/analysis/eid-search/${value}`}>{value}</Link>;
  };

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return orderBy(
      configuration?.initialColumns.map((item) => {
        const column = item;

        if (column?.Header === 'EID') {
          column.Cell = renderCellEid;
        }

        if (column?.accessor === 'actions') {
          column.Cell = renderCellActions;
        }

        return column;
      })
    );
  }, [canDetach]);

  const bulkActions = [
    {
      icon: <IconSymbol className="eid-remove-icon" icon="remove_circle_outline" variant="outlined" />,
      isSeparated: false,
      label: 'Remove Association',
      onClick: ({ getSelectedRows }) => {
        const theEids = getSelectedRows().map((row) => {
          return row?.eid;
        });
        setEids([...theEids]);
        setIsOpen(true);
      },
    },
  ];

  const bulkActionsConfig = {
    bulkActions: canDetach ? bulkActions : [],
    showSelection: !canDetach
      ? () => {
          return false;
        }
      : true,
  };

  const DisabledBulkActions = () => {
    return (
      <div style={{ height: '100%' }}>
        <Layout.Group alignItems="bottom" alignLayout="left" css={{ height: '100%' }} space={0}>
          <User.Capability attributes={['detach']} resource="ActionPaths">
            <DropdownMenu
              after={<IconSymbol icon="keyboard_arrow_down" />}
              before={<IconSymbol icon="grid_view" variant="outlined" />}
              className="disabled-bulk-actions"
              label="Bulk Actions"
            />
          </User.Capability>
        </Layout.Group>
      </div>
    );
  };

  return (
    <ErrorHandler location="src/tables/AssociatedEids/Table.jsx">
      <Styles id="associated-eids">
        <TableComponent
          {...{
            columns,
            configuration: merge({}, configuration, bulkActionsConfig),
            error,
            headerLeft: <DisabledBulkActions />,
            isLoading,
            noDataMessage: "There are no associated eid's.",
            requestArgs,
            requestFunction,
            requestKey,
            rows,
            totalPages,
            totalRecords,
          }}
        />
      </Styles>
    </ErrorHandler>
  );
};

Table.propTypes = {
  error: PropTypes.string,
  headerLeft: PropTypes.element,
  isLoading: PropTypes.bool,
  requestArgs: PropTypes.shape({
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        column: PropTypes.string,
        condition: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
      })
    ),
    page: PropTypes.number,
    size: PropTypes.number,
    sort: PropTypes.string,
  }),
  requestFunction: PropTypes.func,
  requestKey: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      eid: PropTypes.string,
    })
  ),
  setEids: PropTypes.func,
  setIsOpen: PropTypes.func,
  totalPages: PropTypes.number,
  totalRecords: PropTypes.number,
};

Table.defaultProps = {
  error: '',
  headerLeft: null,
  isLoading: false,
  requestArgs: {},
  requestFunction: () => {},
  requestKey: '',
  rows: [],
  setEids: () => {},
  setIsOpen: () => {},
  totalPages: 1,
  totalRecords: 0,
};
