import { useForm } from '@abyss/web/hooks/useForm';
import { Divider } from '@abyss/web/ui/Divider';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid';
import { Layout } from '@abyss/web/ui/Layout';
import { Modal } from '@abyss/web/ui/Modal';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { TextInput } from '@abyss/web/ui/TextInput';
import { TextInputArea } from '@abyss/web/ui/TextInputArea';
import { ToggleSwitch } from '@abyss/web/ui/ToggleSwitch';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { User } from '@src/features/Users/components/User';
import { useCurrentUser } from '@src/features/Users/hooks/useCurrentUser';
import { isEmpty, merge } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import fields from './includes/fields.json';

const { categoryCode, codeDesc, codeId, isValid } = fields;

/**
 * FormModal
 *
 * Prompts the user with a popup window allowing them to edit the code.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const FormModal = (props) => {
  const { assets, currentEntity, handleSave, isOpen, setCurrentEntity, setIsOpen } = props;

  let mode = 'create';

  if (!isEmpty(currentEntity)) {
    mode = 'edit';
  }

  const defaultValues = {
    categoryCode: '',
    codeDesc: '',
    codeId: '',
    isValid: true,
  };

  const [isDisabled, setIsDisabled] = useState(true);
  const [initialValues, setInitialValues] = useState({});

  const form = useForm({ defaultValues });

  const { hasPermission } = useCurrentUser();

  const statusValue = form?.getValues('isValid');

  const canActivate = hasPermission('Admin:Codes', 'activate');
  const canDeactivate = hasPermission('Admin:Codes', 'deactivate');

  const canToggle = useMemo(() => {
    if (statusValue === true) {
      return canDeactivate;
    }

    if (statusValue === false) {
      return canActivate;
    }
  }, [statusValue, canDeactivate, canActivate]);

  /**
   * Mapping data loaded from API to the form state.
   */
  useEffect(() => {
    if (!isEmpty(currentEntity)) {
      const data = merge({}, defaultValues, {
        categoryCode: currentEntity?.categoryCode,
        codeDesc: currentEntity?.codeDesc,
        codeId: currentEntity?.codeId,
        isValid: currentEntity?.isValid,
      });

      if (isEmpty(initialValues)) {
        setInitialValues(data);
      }

      form?.reset(data, {
        keepDirty: false,
        keepDirtyValues: false,
        keepErrors: false,
        keepIsValid: false,
        keepSubmitCount: true,
        keepTouched: false,
        keepValues: false,
      });
    }
  }, [currentEntity]);

  /**
   * handleClose
   *
   * Handles the closing of the modal.
   *
   * @returns {Promise<void>}
   */
  const handleClose = async () => {
    setIsOpen(false);
    setCurrentEntity({});
  };

  /**
   * handleSubmit
   *
   * Handles the form submission.
   *
   * @param submittedValues
   * @returns {Promise<void>}
   */
  const handleSubmit = async (submittedValues) => {
    await handleClose();

    const payload = {};

    payload.codeId = String(submittedValues?.codeId);
    payload.categoryCode = String(submittedValues?.categoryCode);
    payload.codeDesc = String(submittedValues?.codeDesc);
    payload.isValid = Boolean(submittedValues?.isValid);

    await handleSave({
      data: payload,
      id: currentEntity?.id,
      mode,
    });
  };

  const formValues = form?.getValues();

  /**
   * isEqual
   *
   * Compares two objects to determine if they are equal.
   *
   * @param object1
   * @param object2
   * @returns {boolean}
   */
  const isEqual = (object1, object2) => {
    return JSON.stringify(object1) === JSON.stringify(object2);
  };

  /**
   * form validation rules, toggling the save button enablement.
   */
  useEffect(() => {
    if (!isEmpty(currentEntity)) {
      if (isEqual(formValues, initialValues) || !form?.formState?.isValid) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }

    if (isEmpty(currentEntity)) {
      if (!form?.formState?.isValid) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }
  }, [currentEntity, form?.formState.isDirty, form?.formState?.isValid, formValues, form?.formState?.defaultValues]);

  return (
    <ErrorHandler location="src/routes/private/Admin/screens/Codes/List/components/FormModal/FormModal.jsx">
      <Modal isOpen={isOpen} onClose={handleClose} title={mode === 'edit' ? 'Edit Code' : 'Create Code'}>
        <FormProvider autoComplete="off" onSubmit={handleSubmit} state={form}>
          <Modal.Section>
            <Grid>
              <Grid.Col
                span={{
                  xs: '100%',
                }}
              >
                <TextInput
                  {...codeId}
                  isDisabled={mode === 'edit'}
                  onChange={() => {
                    form?.validate(
                      `codeId`,
                      () => {},
                      () => {}
                    );
                  }}
                />
              </Grid.Col>
              <Grid.Col
                span={{
                  xs: '100%',
                }}
              >
                <SelectInput
                  {...categoryCode}
                  isDisabled={mode === 'edit'}
                  onChange={() => {
                    form?.validate(
                      `categoryCode`,
                      () => {},
                      () => {}
                    );
                  }}
                  options={
                    assets?.ListCodeCategories?.data?.content?.map((item) => {
                      return {
                        label: item?.categoryCode,
                        value: item?.categoryCode,
                      };
                    }) || []
                  }
                />
              </Grid.Col>
              <Grid.Col
                span={{
                  xs: '100%',
                }}
              >
                <TextInputArea
                  {...codeDesc}
                  onChange={() => {
                    form?.validate(
                      `codeDesc`,
                      () => {},
                      () => {}
                    );
                  }}
                />
              </Grid.Col>
              <Grid.Col span={{ xs: '100%' }}>
                <Layout.Group>
                  <div>Valid</div>
                  <User.Capability
                    alias="toggle"
                    attributes={['activate', 'deactivate']}
                    isAllowed={canToggle}
                    resource="Admin:Codes"
                  >
                    <ToggleSwitch {...isValid} isDisabled={!canToggle?.value} />
                  </User.Capability>
                </Layout.Group>
              </Grid.Col>
            </Grid>
          </Modal.Section>
          <Modal.Section>
            <Divider height={1} />
            <Layout.Group alignLayout="right">
              <Button onClick={handleClose} variant="outline">
                Cancel
              </Button>
              <Button isDisabled={isDisabled} type="submit" variant="solid">
                {mode === 'edit' ? 'Save' : 'Create'}
              </Button>
            </Layout.Group>
          </Modal.Section>
        </FormProvider>
      </Modal>
    </ErrorHandler>
  );
};

FormModal.propTypes = {
  assets: PropTypes.shape({
    ListCodeCategories: PropTypes.shape({
      data: PropTypes.shape({
        content: PropTypes.arrayOf(
          PropTypes.shape({
            categoryCode: PropTypes.string,
          })
        ),
      }),
    }),
  }),
  currentEntity: PropTypes.shape({
    categoryCode: PropTypes.string,
    codeDesc: PropTypes.string,
    codeId: PropTypes.string,
    id: PropTypes.string,
    isValid: PropTypes.bool,
  }),
  handleSave: PropTypes.func,
  isOpen: PropTypes.bool,
  setCurrentEntity: PropTypes.func,
  setIsOpen: PropTypes.func,
};

FormModal.defaultProps = {
  assets: {},
  currentEntity: {},
  handleSave: () => {},
  isOpen: false,
  setCurrentEntity: () => {},
  setIsOpen: () => {},
};
