import { DropdownMenu } from '@abyss/web/ui/DropdownMenu';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Table as TableComponent } from '@src/components/Table-static';
import { useCurrentUser } from '@src/features/Users/hooks/useCurrentUser';
import { isArray, isEmpty, isUndefined, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { Filters } from './components/Filters';
import configuration from './includes/configuration.json';
import { Styles } from './includes/styles';

/**
 * Table
 *
 * Displays a list of codes within a DataTable.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const { assets, queries, setCurrentEntity, setIsFormModalOpen, setTheQueries } = props;
  const { hasPermission } = useCurrentUser();

  const canEdit = hasPermission('Admin:Codes', 'edit');

  /**
   * renderCellActions
   *
   * Provides the user with a menu of actionable items to perform on a specific action path within the list of action
   * paths.
   *
   * @param row
   * @returns {Element}
   */
  const renderCellActions = ({ row }) => {
    const menuItems = [
      {
        disabled: !canEdit,
        icon: <IconSymbol icon="edit" />,
        onClick: () => {
          setIsFormModalOpen(true);
          setCurrentEntity(row?.original);
        },
        title: 'Edit',
      },
    ];

    return (
      <div
        style={{
          marginLeft: '-10px',
          marginRight: '-10px',
        }}
      >
        <Layout.Group
          css={{
            height: '100%',
            marginLeft: '2px',
            marginRight: '2px',
          }}
        >
          <DropdownMenu
            before={<IconSymbol color="#6F6F6F" icon="more_vert" size={24} />}
            hideLabel
            label=""
            menuItems={menuItems}
          />
        </Layout.Group>
      </div>
    );
  };

  /**
   * renderCellIsValid
   *
   * display the value of the isValid field as a string
   *
   * @param value
   * @returns {string}
   */
  const renderCellIsValid = ({ value }) => {
    return String(value).toUpperCase();
  };

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return orderBy(configuration?.initialColumns, ['order'], ['asc']).map((item) => {
      const column = item;

      if (column.accessor === 'actions') {
        column.Cell = renderCellActions;
      }

      if (column.accessor === 'isValid') {
        column.Cell = renderCellIsValid;
      }

      return column;
    });
  }, [canEdit]);

  /**
   * Each code category requires a separate api call. Combine all the data into a single array to be used as rows.
   */
  const rows = useMemo(() => {
    const theRows = [];

    if (!isUndefined(queries) && !isEmpty(queries)) {
      Object.keys(queries).forEach((queryKey) => {
        const query = queries[queryKey];

        if (!isEmpty(query?.data) && isArray(query?.data)) {
          query?.data?.forEach((item) => {
            theRows.push(item);
          });
        }
      });
    }

    return theRows;
  }, [queries]);

  return (
    <ErrorHandler location="src/routes/private/Admin/screens/Codes/List/components/Table/Table.jsx">
      <Styles>
        <TableComponent
          {...{
            columns,
            configuration,
            headerLeft: <Filters assets={assets} queries={queries} setTheQueries={setTheQueries} />,
            noDataMessage: 'No codes found with selected categories.',
            rows,
          }}
        />
      </Styles>
    </ErrorHandler>
  );
};

Table.propTypes = {
  assets: PropTypes.shape({
    ListCodeCategories: PropTypes.shape({
      data: PropTypes.shape({
        content: PropTypes.arrayOf(
          PropTypes.shape({
            categoryCode: PropTypes.string,
          })
        ),
      }),
    }),
  }),
  queries: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        categoryCode: PropTypes.string,
        code: PropTypes.string,
        codeId: PropTypes.string,
        enabled: PropTypes.bool,
        isValid: PropTypes.bool,
      })
    ),
    key: PropTypes.string,
  }),
  setCurrentEntity: PropTypes.func,
  setIsFormModalOpen: PropTypes.func,
  setTheQueries: PropTypes.func,
};

Table.defaultProps = {
  assets: {},
  queries: {},
  setCurrentEntity: () => {},
  setIsFormModalOpen: () => {},
  setTheQueries: () => {},
};
