import { useRouter } from '@abyss/web/hooks/useRouter';
import { DropdownMenu } from '@abyss/web/ui/DropdownMenu';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { User } from '@src/features/Users/components/User';
import React from 'react';

/**
 * CreateMenu
 *
 * Displays a dropdown menu with options to create an action path with entrance criteria or manually.
 *
 * @returns {Element}
 * @constructor
 */
export const CreateMenu = () => {
  const router = useRouter();

  return (
    <ErrorHandler location="src/features/ActionPaths/components/buttons/CreateMenu/CreateMenu.jsx">
      <User.Capability attributes={['create']} resource="ActionPaths">
        <DropdownMenu
          after={<IconSymbol icon="keyboard_arrow_down" />}
          className="no-padding no-margin"
          data-testid="create-button"
          label="Create"
          menuItems={[
            {
              onClick: () => {
                router?.navigate(`/action-paths/0/create/automatic/draft/step/1`);
              },
              title: 'Create with Entrance Criteria',
            },
            {
              onClick: () => {
                router?.navigate(`/action-paths/0/create/manual/draft/step/1`);
              },
              title: 'Create Manually',
            },
          ]}
          outline
        />
      </User.Capability>
    </ErrorHandler>
  );
};
