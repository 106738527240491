import { useForm } from '@abyss/web/hooks/useForm';
import { dayjs } from '@abyss/web/tools/dayjs';
import { Alert } from '@abyss/web/ui/Alert';
import { Badge } from '@abyss/web/ui/Badge';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { ToggleSwitch } from '@abyss/web/ui/ToggleSwitch';
import { Chart } from '@src/components/Chart';
import { chartTypes } from '@src/components/Chart/includes/chartTypes';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Loader } from '@src/components/Loader';
import { Widget } from '@src/components/Widget';
import { useApi } from '@src/context/Api';
import { useRoutesContext } from '@src/context/Routes';
import { motion } from 'framer-motion';
import { isEmpty, isUndefined, orderBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Filters } from './components';
import { generateTableRowsFromData } from './includes/functions';

/**
 * TagHistory
 *
 * Provides a screen to visualize a risk trend report.
 *
 * @returns {Element}
 * @constructor
 */
export const TagHistory = () => {
  const [currentChartType, setCurrentChartType] = useState('CANDLESTICK_CHART');
  const [isLogarithmicScale, setIsLogarithmicScale] = useState(false);

  const { currentRoute } = useRoutesContext();

  const defaultValues = {
    dateRange: {
      end: dayjs(new Date()).format('MM/DD/YYYY'),
      start: dayjs(new Date()).subtract(2, 'weeks').format('MM/DD/YYYY'),
    },
    frequency: 'DAYS',
    tagCategories: [],
    tags: [],
    tagVariantThreshold: '10000',
    tagVariantThresholdType: 'fixed',
  };

  const [previouslySubmittedValues, setPreviouslySubmittedValues] = useState({});
  const [isLoadingAssets, setIsLoadingAssets] = useState(false);

  const theAssets = ['ListChronoUnits', 'ListTags'];

  const { useApiQueries, useApiQuery } = useApi();

  const [GetTagHistory, { data, isFetching, isLoading }] = useApiQuery('GetTagHistory');

  const assets = useApiQueries(theAssets);

  const form = useForm({ defaultValues });

  const { isSubmitting, isValid } = form.formState;

  /**
   * Determines the overall loading state of all queries.
   */
  useEffect(() => {
    if (
      !isEmpty(assets) &&
      Object.keys(assets).length === theAssets.length &&
      isEmpty(
        Object.keys(assets).filter((queryKey) => {
          const query = assets[queryKey];
          return !(!query?.isLoading && !query?.isFetching);
        })
      )
    ) {
      setIsLoadingAssets(false);
    } else {
      setIsLoadingAssets(true);
    }
  }, [assets, theAssets]);

  /**
   * handleSubmit
   *
   * Calls a remote API to save the form data into a database.
   *
   * @returns {Promise<void>}
   */
  const handleSubmit = useCallback(
    (submittedValues) => {
      if (!isSubmitting && isValid) {
        const payload = {
          beginDate: dayjs(submittedValues?.dateRange?.start).format('YYYY-MM-DD'),
          endDate: dayjs(submittedValues?.dateRange?.end).format('YYYY-MM-DD'),
          timeUnit: String(submittedValues?.frequency),
        };

        if (!isEmpty(submittedValues?.tags)) {
          payload.tagList = submittedValues?.tags;
        }

        if (!isEmpty(submittedValues?.tagCategories)) {
          payload.tagCategoryList = submittedValues?.tagCategories;
        }

        if (submittedValues?.tagVariantThresholdType === 'fixed') {
          payload.minimumDeviation = Number(submittedValues?.tagVariantThreshold);
        }

        if (submittedValues?.tagVariantThresholdType === 'percentage') {
          payload.minimumDeviationPercentage = Number(submittedValues?.tagVariantThreshold);
        }

        GetTagHistory(payload);

        if (previouslySubmittedValues !== submittedValues) {
          setPreviouslySubmittedValues(submittedValues);
        }
      }
    },
    [isSubmitting, isValid]
  );

  /**
   * If the assets have loaded and the form has not been submitted, submit the form with the default values.
   */
  useEffect(() => {
    if (!isLoadingAssets && isEmpty(previouslySubmittedValues)) {
      handleSubmit(defaultValues);
    }
  }, [isLoadingAssets, previouslySubmittedValues]);

  /**
   * tableColumns
   *
   * Generates the columns for the data table.
   *
   * @type {[{Header: string, accessor: string}]}
   */
  const tableColumns = useMemo(() => {
    const theColumns = [
      {
        accessor: 'date',
        Header: 'Date',
        sticky: '0px',
      },
    ];

    orderBy(data?.tagInfoList, ['tag'], ['asc']).forEach((tagInfo) => {
      theColumns.push({
        accessor: `${tagInfo?.tag}-count`,
        Cell: ({ cell, row }) => {
          const colors = {
            black: 'neutral',
            green: 'success',
            red: 'error',
          };

          const totalCount = cell?.value;
          const decreaseCount = row?.original?.[tagInfo?.tag]?.decrease;
          const increaseCount = row?.original?.[tagInfo?.tag]?.increase;

          return (
            <Layout.Stack
              alignItems="right"
              alignLayout="right"
              css={{ margin: 'var(--abyss-space-xs) 0px' }}
              space={0}
            >
              <div>
                <Text>{Number(totalCount).toLocaleString('en-US')}</Text>
              </div>
              <Badge
                css={{
                  backgroundColor: 'transparent !important',
                  paddingRight: '0px !important',
                  textAlign: 'right',
                }}
                variant={decreaseCount > 0 ? colors.green : colors.black}
              >
                {`${decreaseCount > 0 ? '-' : ''}${Number(decreaseCount).toLocaleString('en-US')}`}
              </Badge>
              <Badge
                css={{ backgroundColor: 'transparent !important', paddingRight: '0px !important' }}
                variant={increaseCount > 0 ? colors.red : colors.black}
              >
                {`${increaseCount > 0 ? '+' : ''}${Number(increaseCount).toLocaleString('en-US')}`}
              </Badge>
            </Layout.Stack>
          );
        },
        /* eslint-disable react/prop-types */
        Header: tagInfo?.tag,
      });
    });

    return theColumns;
  }, [data]);

  /**
   * tableRows
   *
   * Generates the rows for the data table.
   *
   * @type {*[]}
   */
  const tableRows = useMemo(() => {
    return generateTableRowsFromData(data);
  }, [data]);

  return (
    <ErrorHandler location="src/routes/private/Dashboards/screens/TagHistory/TagHistory.jsx">
      <motion.div
        animate="open"
        initial={{ opacity: 0 }}
        variants={{
          closed: { opacity: 0 },
          open: { opacity: 1 },
        }}
      >
        <FormProvider autoComplete="off" highlighted onSubmit={handleSubmit} state={form}>
          <Grid>
            <Grid.Col span={{ xs: '100%' }}>
              <Heading offset={0}>{currentRoute?.screenTitle}</Heading>
            </Grid.Col>
            {isLoadingAssets ? (
              <Grid.Col span={{ xs: '100%' }}>
                <Loader verticalAlignment="top" />
              </Grid.Col>
            ) : (
              <React.Fragment>
                <Grid.Col span={{ xs: '100%' }}>
                  <Filters assets={assets} form={form} previouslySubmittedValues={previouslySubmittedValues} />
                </Grid.Col>
                <Grid.Col span={{ xs: '100%' }}>
                  {isLoading || isFetching || isSubmitting ? (
                    <Loader verticalAlignment="top" />
                  ) : (
                    <React.Fragment>
                      {isUndefined(data) || (!isUndefined(data) && isEmpty(data?.tagInfoList)) ? (
                        <Alert title="No data found with specified filters." variant="info" />
                      ) : (
                        <Widget
                          description="Count and variation of tags over time."
                          menu={[
                            {
                              label: 'View as...',
                              onChange: setCurrentChartType,
                              radios: [
                                {
                                  label: chartTypes?.CANDLESTICK_CHART?.label,
                                  value: 'CANDLESTICK_CHART',
                                },
                                {
                                  label: chartTypes?.DATA_TABLE?.label,
                                  value: 'DATA_TABLE',
                                },
                                {
                                  label: chartTypes?.LINE_CHART?.label,
                                  value: 'LINE_CHART',
                                },
                              ],
                              title: 'View as...',
                              value: currentChartType,
                            },
                          ]}
                          title="Tag Trend Report"
                        >
                          {['CANDLESTICK_CHART', 'LINE_CHART'].includes(currentChartType) && (
                            <ToggleSwitch
                              isChecked={isLogarithmicScale}
                              label="Logarithmic Scale"
                              onChange={(event) => {
                                return setIsLogarithmicScale(event?.target?.checked);
                              }}
                            />
                          )}
                          <Chart
                            data={currentChartType === 'CANDLESTICK_CHART' ? data : tableRows}
                            isLogarithmicScale={isLogarithmicScale}
                            legendLimit={15}
                            maxHeight="calc(100vh - 500px) !important"
                            minHeight="600px !important"
                            tableConfiguration={{
                              initialColumns: tableColumns,
                              initialState: {
                                sortBy: [
                                  {
                                    desc: true,
                                    id: 'date',
                                  },
                                ],
                              },
                              stickyHeaders: true,
                            }}
                            type={currentChartType}
                          />
                        </Widget>
                      )}
                    </React.Fragment>
                  )}
                </Grid.Col>
              </React.Fragment>
            )}
          </Grid>
        </FormProvider>
      </motion.div>
    </ErrorHandler>
  );
};
