import { useFormFieldArray } from '@abyss/web/hooks/useFormFieldArray';
import { Box } from '@abyss/web/ui/Box';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useRoutesContext } from '@src/context/Routes';
import { NotesField } from '@src/features/ActionPaths/components/misc/Wizard/components/fields/Notes';
import {
  RemediationMethodField
} from '@src/features/ActionPaths/components/misc/Wizard/components/fields/RemediationMethod';
import { ScopeCodeField } from '@src/features/ActionPaths/components/misc/Wizard/components/fields/ScopeCode';
import { useWizardContext } from '@src/features/ActionPaths/components/misc/Wizard/context';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { Table } from './components/Table/Table';
import { Styles } from './includes/styles';

/**
 * RemediateRecords
 *
 * Provides the user with a screen to browse the risk records found based on the entrance criteria (filters) specified.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const RemediateRecords = (props) => {
  const { assets, defaultValues, form, isLoading } = props;

  const { currentStep, mode } = useWizardContext();

  const sources = assets?.ListSources?.data || [];
  const actions = assets?.ListActions?.data || [];
  const chronoUnits = assets?.ListChronoUnits?.data || [];

  const { currentRoute } = useRoutesContext();

  const { append, fields, remove, replace, swap } = useFormFieldArray({
    control: form?.control,
    name: 'remediation.assignments',
  });

  const actionPathScopeCode = form.getValues('remediation.actionPathScopeCode');
  const remediationMethod = form.getValues('remediation.remediationMethod');
  const notes = form.getValues('remediation.notes');

  /**
   * Sets an initial row.
   */
  useEffect(() => {
    (async () => {
      if (isEmpty(fields)) {
        replace([
          {
            actionId: '',
            actionName: '',
            impactedSource: '',
            retryAttempts: '',
            retryInterval: '',
            retryIntervalUnit: '',
          },
        ]);
      }
    })();
  }, [fields]);

  /**
   * validate after setting initial row.
   */
  useEffect(() => {
    if (fields?.length === 1) {
      form.validate(
        `remediation.assignments.0.impactedSource`,
        () => {},
        () => {}
      );
    }
  }, [fields]);

  /**
   * handleReset
   *
   * @TODO - Needs description.
   *
   * @returns {Promise<void>}
   */
  const handleReset = async () => {
    await form.setValue('remediation.assignments', defaultValues?.remediation?.assignments);
    replace(defaultValues?.remediation?.assignments);
  };

  /**
   * validateField
   *
   * @TODO - Needs description.
   *
   * @param field
   */
  const validateField = (field) => {
    form.validate(
      `remediation[${field}]`,
      () => {},
      () => {}
    );
  };

  /**
   * Validate all repeatable fields within a row when a value changes.
   */
  useEffect(() => {
    if (isLoading === false) {
      if (!isEmpty(actionPathScopeCode)) {
        validateField('actionPathScopeCode');
      }
      if (!isEmpty(remediationMethod)) {
        validateField('remediationMethod');
      }
      validateField('notes');
    }
  }, [actionPathScopeCode, remediationMethod, notes, isLoading]);

  return (
    <ErrorHandler location="src/features/ActionPaths/components/misc/Wizard/components/steps/RemediateRecords/RemediateRecords.jsx">
      <Styles>
        <Grid>
          <Grid.Col
            css={{ paddingTop: themeConfiguration?.theme?.space?.lg }}
            span={{
              xs: '100%',
            }}
          >
            <Heading offset={1}>{currentStep?.label} </Heading>
            <p>{currentStep?.description}</p>
            <Box
              color="$gray1"
              css={{
                border: '1px solid',
                borderColor: themeConfiguration?.theme?.colors?.gray3,
                position: 'relative',
              }}
              height="auto"
            >
              <Flex alignItems="center" style={{ position: 'relative', width: '100%' }}>
                <div style={{ marginRight: 'var(--abyss-space-lg)', width: '20%' }}>
                  <RemediationMethodField form={form} />
                </div>
                {mode !== 'manual' && <ScopeCodeField form={form} />}
                <div style={{ marginLeft: mode !== 'manual' && 'var(--abyss-space-lg)', width: '25%' }}>
                  <NotesField form={form} />
                </div>
              </Flex>
            </Box>
          </Grid.Col>

          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            <Layout.Group alignLayout="right">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                id="addField"
                onClick={() => {
                  return append({
                    actionId: '',
                    actionName: '',
                    impactedSource: '',
                    retryAttempts: '',
                    retryInterval: '',
                    retryIntervalUnit: '',
                  });
                }}
                variant="custom"
              >
                <Flex alignItems="center">
                  <div>
                    <IconSymbol icon="add_circle" variant="filled" />
                    <IconSymbol icon="add_circle" variant="outlined" />
                  </div>
                  <div>Add Action</div>
                </Flex>
              </Link>
            </Layout.Group>
            <Table
              actions={actions}
              append={append}
              chronoUnits={chronoUnits}
              dataKey={`${currentRoute?.key}-remediateRecords`}
              fields={fields}
              form={form}
              handleReset={handleReset}
              isLoading={isLoading}
              remove={remove}
              replace={replace}
              sources={sources}
              swap={swap}
            />
          </Grid.Col>
        </Grid>
      </Styles>
    </ErrorHandler>
  );
};

RemediateRecords.propTypes = {
  assets: PropTypes.shape({
    ListActions: PropTypes.shape({
      data: PropTypes.shape({
        content: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
          })
        ),
      }),
    }),
    ListChronoUnits: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    }),
    ListSources: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    }),
  }),
  defaultValues: PropTypes.shape({
    remediation: PropTypes.shape({
      assignments: PropTypes.arrayOf(
        PropTypes.shape({
          actionId: PropTypes.string,
          actionName: PropTypes.string,
          impactedSource: PropTypes.string,
          retryAttempts: PropTypes.string,
          retryInterval: PropTypes.string,
          retryIntervalUnit: PropTypes.string,
        })
      ),
    }),
  }),
  form: PropTypes.shape({
    control: PropTypes.shape({
      fields: PropTypes.shape({
        append: PropTypes.func,
        remove: PropTypes.func,
        replace: PropTypes.func,
        swap: PropTypes.func,
      }),
    }),
    getValues: PropTypes.func,
    setValue: PropTypes.func,
    validate: PropTypes.func,
  }),
  isLoading: PropTypes.bool,
};

RemediateRecords.defaultProps = {
  assets: {},
  defaultValues: {},
  form: {},
  isLoading: false,
};
