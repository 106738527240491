import { useForm } from '@abyss/web/hooks/useForm';
import { Flex } from '@abyss/web/ui/Flex';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Layout } from '@abyss/web/ui/Layout';
import { Tabs as AbyssTabs } from '@abyss/web/ui/Tabs';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Loader } from '@src/components/Loader';
import { RepeatableFields } from '@src/components/RepeatableFields';
import { useApi } from '@src/context/Api';
import { User } from '@src/features/Users/components/User';
import { useCurrentUser } from '@src/features/Users/hooks/useCurrentUser';
import { motion } from 'framer-motion';
import { isArray, isEmpty, isNull, isUndefined } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';

import { useAssets } from './hooks/useAssets';
import { useSave } from './hooks/useSave';
import fields from './includes/fields.json';
import { Styles } from './includes/styles';

/**
 * AlertConfig
 *
 * Screen to list and manage alert configurations.
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const AlertConfig = () => {
  const { useApiQuery } = useApi();

  const { data: assets, isLoading: isLoadingAssets } = useAssets();

  const [ListAlertConfigurations, { data, isFetching, isLoading, refetch }] = useApiQuery('ListAlertConfigurations');

  const { hasPermission } = useCurrentUser();

  const canEdit = hasPermission('Admin:AlertConfig', 'edit');

  const defaultValues = {
    Daily_Tags_Count_Red: [
      {
        configScopeItem: 'default',
        configurationId: '',
        deviationSensitivityPercAAD: '0',
        deviationSensitivityPercMAD: '0',
        minRecordDiff: '0',
        minTotalRecords: '0',
      },
    ],
  };

  const form = useForm({ defaultValues });
  const { isDirty, isSubmitting, isValid } = form.formState;

  /**
   * Get the list of alert configurations from the remote api.
   */
  useEffect(() => {
    if (isUndefined(data) && !isLoading && !isFetching) {
      ListAlertConfigurations();
    }
  }, [data, isLoading, isFetching]);

  /**
   * Set form values from the api data.
   */
  useEffect(() => {
    const values = {};

    if (isArray(data?.content) && !isEmpty(data?.content)) {
      data?.content?.forEach((item) => {
        if (isUndefined(values?.[item?.reportId])) {
          values[item?.reportId] = [];
        }

        values[item?.reportId].push({
          configScopeItem:
            item?.defaultForReport === true && isNull(item?.configScopeItem)
              ? 'default'
              : String(item?.configScopeItem),
          configurationId: String(item?.configurationId),
          deviationSensitivityPercAAD: String(item?.deviationSensitivityPercAAD),
          deviationSensitivityPercMAD: String(item?.deviationSensitivityPercMAD),
          minRecordDiff: String(item?.minRecordDiff),
          minTotalRecords: String(item?.minTotalRecords),
        });
      });
    }

    form?.reset(values, {
      keepDirty: false,
      keepDirtyValues: false,
      keepErrors: false,
      keepIsValid: false,
      keepSubmitCount: true,
      keepTouched: false,
      keepValues: false,
    });
  }, [data?.content]);

  /**
   * tagOptions
   *
   * Compiles a list of selectable options based on the tags returned from the api.
   *
   * @type {[{section: string, items: [{label: string, value: string}]}]}
   */
  const tagOptions = useMemo(() => {
    const theOptions = [{ items: [{ label: 'Default', value: 'default' }], section: 'Default' }];

    if (!isUndefined(assets?.ListTags?.data?.tagsList) && !isEmpty(assets?.ListTags?.data?.tagsList)) {
      Object.keys(assets?.ListTags?.data?.tagsList).forEach((categoryCode) => {
        const tagOption = {
          items: [],
          section: '',
        };
        assets?.ListTags?.data?.tagsList[categoryCode].forEach((tag) => {
          if (isEmpty(tagOption.section)) {
            tagOption.section = tag?.categoryDesc;
          }
          const tagItem = {
            label: `${tag?.code} (${tag?.categoryCode})`,
            value: tag?.tag,
          };
          if (!tagOption.items.includes(tagItem)) {
            tagOption.items.push(tagItem);
          }
        });

        if (!theOptions.includes(tagOption)) {
          theOptions.push(tagOption);
        }
      });
    }

    return theOptions;
  }, [assets?.ListTags?.data?.tagsList]);

  /**
   * getFields
   *
   * Get the fields for a specific report id.
   *
   * @param reportId
   * @returns {*}
   */
  const getFields = (reportId = '') => {
    let theFields = fields?.[reportId];

    if (isArray(theFields) && !isEmpty(theFields)) {
      theFields = theFields.map((field) => {
        const theField = { ...field };

        if (field?.model === 'configScopeItem') {
          theField.options = tagOptions;
        }

        return theField;
      });
    }

    return theFields;
  };

  const { handleSave, saveEvent } = useSave('Daily_Tags_Count_Red');

  /**
   * handleSubmit
   *
   * Calls a remote API to save the form data into a database.
   *
   * @returns {Promise<void>}
   */
  const handleSubmit = useCallback(
    async (submittedValues) => {
      if (!isSubmitting && isValid && !saveEvent?.isPending && isDirty) {
        let toSave = [];

        if (!isEmpty(submittedValues?.['Daily_Tags_Count_Red'])) {
          const values = submittedValues?.['Daily_Tags_Count_Red'];

          toSave = values.map((value) => {
            let theValue = { ...value, ...{ reportId: 'Daily_Tags_Count_Red' } };

            const theOriginalValue = data?.content?.find((originalValue) => {
              return originalValue?.configurationId === value?.configurationId;
            });

            if (!isUndefined(theOriginalValue)) {
              theValue = { ...theOriginalValue, ...value };
            }

            return theValue;
          });

          await handleSave({ ['Daily_Tags_Count_Red']: toSave }, refetch);
        }
      }
    },
    [isSubmitting, isValid, saveEvent, isDirty]
  );

  return (
    <ErrorHandler location="src/routes/private/Admin/screens/AlertConfig/AlertConfig.jsx">
      <Styles>
        <motion.div
          animate="open"
          initial={{ opacity: 0 }}
          variants={{
            closed: { opacity: 0 },
            open: { opacity: 1 },
          }}
        >
          <FormProvider autoComplete="off" highlighted onSubmit={handleSubmit} state={form}>
            <Grid>
              <Grid.Col
                span={{
                  xs: '100%',
                }}
              >
                <Flex alignItems="center" direction="row">
                  <Heading offset={0}>Alert Config</Heading>
                </Flex>
              </Grid.Col>

              {isLoading || isFetching || isLoadingAssets ? (
                <Grid.Col span={{ xs: '100%' }}>
                  <Loader verticalAlignment="top" />
                </Grid.Col>
              ) : (
                [
                  <Grid.Col
                    key="tabs"
                    span={{
                      xs: '100%',
                    }}
                  >
                    <AbyssTabs grow={false}>
                      <AbyssTabs.Tab key="Daily_Tags_Count_Red" label="Daily_Tags_Count_Red" subText="">
                        <RepeatableFields
                          accessor="Daily_Tags_Count_Red"
                          defaultValues={defaultValues['Daily_Tags_Count_Red'][0]}
                          disabledFields={{ 0: ['configScopeItem'] }}
                          fields={getFields('Daily_Tags_Count_Red')}
                          form={form}
                          permissions={{
                            add: {
                              attributes: ['edit'],
                              isAllowed: canEdit,
                              resource: 'Admin:AlertConfig',
                            },
                            edit: {
                              attributes: ['edit'],
                              isAllowed: false,
                              resource: 'Admin:AlertConfig',
                            },
                            remove: {
                              attributes: ['edit'],
                              isAllowed: false,
                              resource: 'Admin:AlertConfig',
                            },
                          }}
                        />
                      </AbyssTabs.Tab>
                    </AbyssTabs>
                  </Grid.Col>,
                  <Grid.Col key="save" span={{ xs: '100%' }}>
                    <Layout.Group alignLayout="right">
                      <User.Capability attributes={['edit']} isAllowed={canEdit} resource="Admin:AlertConfig">
                        <Button type="submit" variant="solid">
                          Save
                        </Button>
                      </User.Capability>
                    </Layout.Group>
                  </Grid.Col>,
                ]
              )}
            </Grid>
          </FormProvider>
        </motion.div>
      </Styles>
    </ErrorHandler>
  );
};
