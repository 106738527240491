import { Badge } from '@abyss/web/ui/Badge';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';
import { NavMenuPrimitives } from '@abyss/web/ui/NavMenu';
import { Text } from '@abyss/web/ui/Text';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useCurrentUser } from '@src/features/Users/hooks/useCurrentUser';
import React from 'react';

import { MenuItem } from '../MenuItem';
import { Styles as SubMenuStyles } from '../SubMenu/includes/styles';

/**
 * UserMenu
 *
 * Provides a menu with user-related actions.
 *
 * @returns {Element}
 * @constructor
 */
export const UserMenu = () => {
  const { currentRole, displayName } = useCurrentUser();

  return (
    <ErrorHandler location="src/layouts/default/Header/components/Navigation/components/UserMenu/UserMenu.jsx">
      <NavMenuPrimitives.Item>
        <NavMenuPrimitives.Trigger variant="inverted">
          <MenuItem iconName="account_box" title={displayName} />
        </NavMenuPrimitives.Trigger>
        <NavMenuPrimitives.Content>
          <SubMenuStyles>
            <NavMenuPrimitives.Columns>
              <NavMenuPrimitives.Column>
                <NavMenuPrimitives.Item>
                  <Text>
                    <Layout.Group>
                      Permissions:
                      <Badge
                        ariaText="information"
                        icon={<IconSymbol color="$info1" icon="info" size="$sm" variant="outlined" />}
                        variant="info"
                      >
                        {currentRole} Access
                      </Badge>
                    </Layout.Group>
                  </Text>
                </NavMenuPrimitives.Item>
                <NavMenuPrimitives.MenuItem href="/logout" title="Logout" />
              </NavMenuPrimitives.Column>
            </NavMenuPrimitives.Columns>
          </SubMenuStyles>
        </NavMenuPrimitives.Content>
      </NavMenuPrimitives.Item>
    </ErrorHandler>
  );
};
