import { Flex } from '@abyss/web/ui/Flex';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Link } from '@abyss/web/ui/Link';
import { NumberInput } from '@abyss/web/ui/NumberInput';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Table as TableComponent } from '@src/components/Table-static';
import { User } from '@src/features/Users/components/User';
import { isEmpty, merge, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import configuration from './includes/configuration.json';
import { Styles } from './includes/styles';

const Components = {
  NumberInput,
  SelectInput,
};

/**
 * Table
 *
 * Provides the user with a table to specify repeatable fields to search risk records by.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const { accessor, disabledFields, fields, permissions, remove, repeatableFields, rows } = props;

  /**
   * renderCellField
   *
   * @TODO Needs description.
   *
   * @param args
   * @returns {JSX.Element}
   */
  const renderCell = (args) => {
    const { row } = args;
    const { index } = row;

    const theField = fields?.find((field) => {
      return field?.model === args?.column?.id;
    });

    const TheComponent = Components[theField?.component];

    return (
      <User.Capability
        alias="edit"
        attributes={permissions?.edit?.attributes}
        isAllowed={permissions?.edit?.isAllowed}
        resource={permissions?.edit?.resource}
      >
        <TheComponent
          {...{
            ...theField,
            ...{
              isDisabled: disabledFields?.[index]?.includes(theField?.model),
              label: ' 123',
              model: `${accessor}[${index}][${theField?.model}]`,
            },
          }}
        />
      </User.Capability>
    );
  };

  /**
   * renderCellActions
   *
   * @TODO Needs description.
   *
   * @param args
   * @returns {JSX.Element}
   */
  const renderCellActions = (args) => {
    const { row } = args;
    const { index } = row;

    if (index === 0) {
      return null;
    }

    return (
      <User.Capability
        alias="remove"
        attributes={permissions?.remove?.attributes}
        isAllowed={permissions?.remove?.isAllowed}
        resource={permissions?.remove?.resource}
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          className="removeRow"
          onClick={() => {
            remove(index);
          }}
          variant="custom"
        >
          <Flex alignItems="center">
            <div>
              <IconSymbol icon="cancel" variant="filled" />
              <IconSymbol icon="cancel" variant="outlined" />
            </div>
            <div>Remove</div>
          </Flex>
        </Link>
      </User.Capability>
    );
  };

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    const theColumns = orderBy(fields, ['order'], ['asc'])?.map((field) => {
      return {
        accessor: field?.model,
        Cell: renderCell,
        disableSortBy: true,
        Header: field?.label,
        order: field?.order,
      };
    });

    theColumns.push({
      accessor: 'actions',
      Cell: renderCellActions,
      disableSortBy: true,
      Header: ' ',
    });

    return theColumns;
  }, [fields, permissions?.edit?.isAllowed, permissions?.remove?.isAllowed]);

  return (
    <ErrorHandler location="src/components/RepeatableFields/components/Table/Table.jsx">
      {!isEmpty(fields) && !isEmpty(repeatableFields) ? (
        <Styles>
          <TableComponent
            {...{
              columns,
              configuration: merge({}, configuration, {
                data: repeatableFields,
                reorderRows: false,
              }),
              dataKey: accessor,
              rows,
            }}
          />
        </Styles>
      ) : (
        <React.Fragment />
      )}
    </ErrorHandler>
  );
};

Table.propTypes = {
  accessor: PropTypes.string,
  disabledFields: PropTypes.shape({}),
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  permissions: PropTypes.shape({
    add: {
      attributes: [PropTypes.arrayOf(PropTypes.string)],
      isAllowed: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
      resource: PropTypes.string,
    },
    edit: {
      attributes: [PropTypes.arrayOf(PropTypes.string)],
      isAllowed: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
      resource: PropTypes.string,
    },
    remove: {
      attributes: [PropTypes.arrayOf(PropTypes.string)],
      isAllowed: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
      resource: PropTypes.string,
    },
  }),
  remove: PropTypes.func,
  repeatableFields: PropTypes.arrayOf(PropTypes.shape({})),
  rows: PropTypes.arrayOf(PropTypes.shape({})),
};

Table.defaultProps = {
  accessor: '',
  disabledFields: {},
  fields: [],
  permissions: {
    add: {
      attributes: [],
      isAllowed: null,
      resource: '',
    },
    edit: {
      attributes: [],
      isAllowed: null,
      resource: '',
    },
    remove: {
      attributes: [],
      isAllowed: null,
      resource: '',
    },
  },
  remove: () => {},
  repeatableFields: [],
  rows: [],
};
