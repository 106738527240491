import { ErrorHandler } from '@src/components/ErrorHandler';
import { Widget } from '@src/components/Widget';
import PropTypes from 'prop-types';
import React from 'react';

import { HorizontalBarChart } from './components/HorizontalBarChart';

/**
 * Widget: UntrustedSourceRecords
 *
 * Displays the number of untrusted records by source that are with or without an action path.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const UntrustedSourceRecords = (props) => {
  const { data } = props;

  return (
    <ErrorHandler location="src/routes/private/Dashboards/screens/Risk/components/widgets/UntrustedSourceRecords/UntrustedSourceRecords.jsx">
      <Widget description="Records by source that are with or without an action path" title="Untrusted Source Records">
        <HorizontalBarChart data={data} />
      </Widget>
    </ErrorHandler>
  );
};

UntrustedSourceRecords.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      countActiveActionPath: PropTypes.number,
      countNoActiveActionPath: PropTypes.number,
      countTotal: PropTypes.number,
      untrustedSource: PropTypes.string,
    })
  ),
};

UntrustedSourceRecords.defaultProps = {
  data: [],
};
