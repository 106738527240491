import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { User } from '@src/features/Users/components/User';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ActivationModal } from '../../modals/Activation';

/**
 * Button: Activate
 *
 * This button is used to activate the selected version of the entity.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Activate = (props) => {
  const { activatedVersion, commonCriteriaVersions, currentEntity, form, refetch, setFocusedEntity } = props;

  const [isOpen, setIsOpen] = useState(false);

  const { isDirty, isValid } = form.formState;

  const selectedVersionValue = form?.watch('version');
  const currentFilters = form?.watch('filters');

  return (
    <ErrorHandler location="src/routes/private/Admin/screens/CommonCriteria/components/buttons/Activate/Activate.jsx">
      <User.Capability attributes={['activate']} resource="Admin:CommonCriteria">
        <Button
          isDisabled={
            !isDirty ||
            !isValid ||
            (activatedVersion?.version === selectedVersionValue && !isDirty) ||
            (activatedVersion?.version === selectedVersionValue && !isValid) ||
            isEqual(activatedVersion?.criteria, currentFilters)
          }
          onClick={async () => {
            setIsOpen(true);
          }}
          type="submit"
          variant="solid"
        >
          Activate
        </Button>
      </User.Capability>
      {isOpen && (
        <ActivationModal
          activatedVersion={activatedVersion}
          commonCriteriaVersions={commonCriteriaVersions}
          currentEntity={currentEntity}
          formValues={form.getValues()}
          isOpen={isOpen}
          refetch={refetch}
          selectedVersionValue={selectedVersionValue}
          setFocusedEntity={setFocusedEntity}
          setIsOpen={setIsOpen}
        />
      )}
    </ErrorHandler>
  );
};

Activate.propTypes = {
  activatedVersion: PropTypes.shape({
    criteria: PropTypes.arrayOf(PropTypes.string),
    version: PropTypes.string,
  }),
  commonCriteriaVersions: PropTypes.number,
  currentEntity: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  form: PropTypes.shape({
    formState: PropTypes.shape({
      isDirty: PropTypes.bool,
      isValid: PropTypes.bool,
    }),
    getValues: PropTypes.func,
    watch: PropTypes.func,
  }),
  refetch: PropTypes.func,
  setFocusedEntity: PropTypes.func,
};

Activate.defaultProps = {
  activatedVersion: {},
  commonCriteriaVersions: 0,
  currentEntity: {},
  form: {},
  refetch: () => {},
  setFocusedEntity: () => {},
};
