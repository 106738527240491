import { ErrorHandler } from '@src/components/ErrorHandler';
import { Widget } from '@src/components/Widget';
import { ActionsTable } from '@src/features/ActionPaths/components/tables/Actions';
import PropTypes from 'prop-types';
import React from 'react';

import { Description } from './components/Description';

/**
 * Widget: RemediationActions
 *
 * @TODO Needs description.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const RemediationActions = (props) => {
  const { assignments, remediationType, scopeCode } = props;

  return (
    <ErrorHandler location="src/features/Risk/components/widgets/RemediationActions/RemediationActions.jsx">
      <Widget
        description={<Description remediationType={remediationType || ''} scopeCode={scopeCode || ''} />}
        title="Remediation Actions"
      >
        <ActionsTable rows={assignments || []} />
      </Widget>
    </ErrorHandler>
  );
};

RemediationActions.propTypes = {
  assignments: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
      remediationType: PropTypes.string,
      scopeCode: PropTypes.string,
      source: PropTypes.string,
      status: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  remediationType: PropTypes.string,
  scopeCode: PropTypes.string,
};

RemediationActions.defaultProps = {
  assignments: [],
  remediationType: '',
  scopeCode: '',
};
