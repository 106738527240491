import { Badge } from '@abyss/web/ui/Badge';
import { Divider } from '@abyss/web/ui/Divider';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { ErrorHandler } from '@src/components/ErrorHandler';
import fields from '@src/features/Criteria/components/misc/Filters/components/Table/includes/fields.json';
import { useCommonCriteria } from '@src/features/Criteria/hooks/useCommonCriteria';
import { isArray, isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { Styles } from './includes/styles';

/**
 * AppliedFilterCriteria
 *
 * Shows the currently applied filters.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const AppliedFilterCriteria = (props) => {
  const { criteria, filters, label, showLabel } = props;

  const { criteriaList, data, fetch, isLoading, namesList } = useCommonCriteria();

  useEffect(() => {
    if (
      !isLoading &&
      isEmpty(data) &&
      !isUndefined(criteria?.entrance?.commonIds) &&
      !isEmpty(criteria?.entrance?.commonIds)
    ) {
      fetch(criteria);
    }
  }, [criteria, data, isLoading]);

  /**
   * getLabel
   *
   * @TODO Needs description.
   *
   * @param theCondition
   * @returns {*}
   */
  const getLabel = (theCondition) => {
    const matchedCondition = fields?.condition?.options?.find((condition) => {
      return condition?.value === theCondition;
    });
    return matchedCondition?.label;
  };

  /**
   * theFilter
   *
   * @TODO Needs description.
   *
   * @param theFilter
   * @returns {*}
   */
  const getFilter = (theFilter) => {
    const matchedFilter = fields?.column?.options?.find((filter) => {
      return filter?.value === theFilter;
    });
    return matchedFilter?.label;
  };

  /**
   * getValue
   *
   * @TODO Needs description.
   *
   * @param theValue
   * @returns {*}
   */
  const getValue = (theValue) => {
    let value = theValue;

    if (isArray(theValue)) {
      value = theValue.join(', ');
    }

    return value;
  };

  const AdditionalCriteriaToolTip = (args) => {
    const { filter } = args;
    return (
      <Layout.Stack alignItems="left" alignLayout="left" grow space={themeConfiguration?.theme?.space?.xs}>
        <div>
          <strong>{getFilter(filter?.column)}</strong>
        </div>
        <div>
          <p>{getLabel(filter?.condition)}:</p>
          <Text color="inherit">{getValue(filter?.value)}</Text>
        </div>
      </Layout.Stack>
    );
  };

  const CommonCriteriaToolTip = (args) => {
    const { filters: theFilters } = args;

    return (
      <Layout.Stack alignItems="left" alignLayout="left" grow space={themeConfiguration?.theme?.space?.xs}>
        {theFilters?.map((filter, index) => {
          return [
            <div key={`filter-name-${filter?.name}`}>
              <Text color="inherit">
                <p>
                  <strong>{filter?.name}</strong> (v{filter?.activeVersionNbr})
                </p>
              </Text>
            </div>,
            <Layout.Stack
              alignItems="left"
              alignLayout="left"
              grow
              key={`filter-criteria-${filter?.name}`}
              space={themeConfiguration?.theme?.space?.xs}
            >
              {filter?.activeCommonCriteriaVersion?.criteria.map((criteriaFilter) => {
                return [
                  <div key={`criteria-column-${criteriaFilter?.column}`}>
                    <strong>{getFilter(criteriaFilter?.column)}</strong>
                  </div>,
                  <div key={`criteria-condition-${criteriaFilter?.condition}`}>
                    <p>{getLabel(criteriaFilter?.condition)}:</p>
                    <Text color="inherit">{getValue(criteriaFilter?.value)}</Text>
                  </div>,
                ];
              })}
            </Layout.Stack>,
            index < theFilters.length - 1 && <Divider />,
          ];
        })}
      </Layout.Stack>
    );
  };

  return (
    <ErrorHandler location="src/features/Criteria/components/misc/AppliedFilterCriteria/AppliedFilterCriteria.jsx">
      <Styles>
        <Layout.Group css={{ margin: 0 }}>
          {showLabel === true && !isEmpty(label) && <Text fontWeight="bold">{label}:</Text>}

          {label === 'Common Criteria' ? (
            <Badge
              css={{
                '.abyss-badge-content': { fontWeight: 'normal' },
                '.abyss-icon': {
                  fontSize: 'inherit',
                  fontWeight: 'bold',
                  lineHeight: 'inherit',
                },
              }}
              rounded
              variant="neutral"
            >
              <Layout.Group space={6}>
                <div>
                  <IconSymbol icon="settings" variant="outlined" />
                </div>
                <div>
                  <Tooltip
                    align="start"
                    asChild={false}
                    content={<CommonCriteriaToolTip filters={criteriaList} />}
                    position="bottom"
                    positionOffset={Number(themeConfiguration?.theme?.space?.md.replace('px', ''))}
                  >
                    <Layout.Group space={6}>{namesList?.join(', \n')}</Layout.Group>
                  </Tooltip>
                </div>
              </Layout.Group>
            </Badge>
          ) : (
            <React.Fragment>
              {filters?.map((filter) => {
                return (
                  <Badge
                    css={{
                      '.abyss-badge-content': { fontWeight: 'normal' },
                      '.abyss-icon': {
                        fontSize: 'inherit',
                        fontWeight: 'bold',
                        lineHeight: 'inherit',
                      },
                    }}
                    key={filter?.column}
                    rounded
                    variant="neutral"
                  >
                    <Layout.Group space={6}>
                      <div>
                        {filter?.column === 'TAGS' && <IconSymbol icon="label" variant="filled" />}
                        {filter?.column.toLowerCase().includes('date') && (
                          <IconSymbol icon="calendar_month" variant="outlined" />
                        )}
                        {filter?.column.toLowerCase().includes('count') && (
                          <IconSymbol icon="numbers" variant="outlined" />
                        )}
                        {filter?.column === 'ACTION_STATUS' && <IconSymbol icon="help_outline" variant="outlined" />}
                        {filter?.column === 'ACTION_PATH_ID' && (
                          <IconSymbol icon="create_new_folder" variant="outlined" />
                        )}
                      </div>
                      <div>
                        <Tooltip
                          align="start"
                          asChild={false}
                          content={<AdditionalCriteriaToolTip filter={filter} />}
                          position="bottom"
                          positionOffset={Number(themeConfiguration?.theme?.space?.md.replace('px', ''))}
                        >
                          <Layout.Group css={{ maxWidth: '200px', overflow: 'hidden' }} space={6}>
                            <div>{getFilter(filter?.column)}:</div>
                            {getLabel(filter?.condition)}
                            <div>&quot;{getValue(filter?.value)}&quot;</div>
                          </Layout.Group>
                        </Tooltip>
                      </div>
                    </Layout.Group>
                  </Badge>
                );
              })}
            </React.Fragment>
          )}
        </Layout.Group>
      </Styles>
    </ErrorHandler>
  );
};

AppliedFilterCriteria.propTypes = {
  criteria: PropTypes.shape({
    entrance: PropTypes.shape({
      commonIds: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      column: PropTypes.string,
      condition: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
    })
  ),
  label: PropTypes.string,
  showLabel: PropTypes.bool,
};

AppliedFilterCriteria.defaultProps = {
  criteria: {
    entrance: {
      commonIds: [],
    },
  },
  filters: [],
  label: '',
  showLabel: true,
};
